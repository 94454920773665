import { AnchorButton, Dialog, H1, H6, OverlayToaster, Position, ToasterInstance } from "@blueprintjs/core";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { Flex } from "rebass";
import Loading from "../assets/loading.svg";
import { calculateRisk } from "../utils/risk_profile/functions";
import { RiskButton } from "./RiskComponent";

interface RiskAnswer {
    [key: string]: {
        [key: string]: string;
    };
}

let sliderExample: Slider;

const Risk = () => {
    let toaster = React.createRef<OverlayToaster>();

    const navigate = useNavigate();

    const intl = useIntl();
    const [nav, setNav] = useState(sliderExample);
    const [slide, setSlide] = useState(0);
    const [ageRange, setAgeRange] = useState(-1);
    const [withdrawAnnually, setWithdrawAnnually] = useState(-1);
    const [incomeAssets, setIncomeAssets] = useState(-1);
    const [takeRisk, setTakeRisk] = useState(-1);
    const [preference, setPreference] = useState(-1);
    const [knowledgable, setKnowledgable] = useState(-1);
    const [experience, setExperience] = useState(-1);
    const [perception, setPerception] = useState(-1);
    const [loseMoney, setLoseMoney] = useState(-1);
    const [investmentLength, setInvestmentLength] = useState(-1);
    const [hasSelected, setHasSelected] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const dialogProps = {
        autoFocus: true,
        canEscapeKeyClose: false,
        canOutsideClickClose: false,
        enforceFocus: true,
        shouldReturnFocusOnClose: true,
        usePortal: true,
    };

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 0,
        swipeToSlide: false,
        swipe: false,
        centerMode: true,
        autoPlay: false,
        adaptiveHeight: true,
        beforeChange: changeSlide,
    };

    const riskAnswers: RiskAnswer = {
        ageRange: {
            5: "18 - 24",
            4: "25 - 34",
            3: "35 - 44",
            2: "45 - 59",
            1: intl.formatMessage({ id: "over_60" }),
        },
        withdrawAnnually: {
            0: intl.formatMessage({ id: "no" }),
            1: intl.formatMessage({ id: "yes" }),
        },
        incomeAssets: {
            0: intl.formatMessage({ id: "no" }),
            1: intl.formatMessage({ id: "yes" }),
        },

        takeRisk: {
            1: intl.formatMessage({ id: "strongly_disagree" }),
            2: intl.formatMessage({ id: "disagree" }),
            3: intl.formatMessage({ id: "neutral" }),
            4: intl.formatMessage({ id: "agree" }),
            5: intl.formatMessage({ id: "strongly_agree" }),
        },
        preference: {
            1: intl.formatMessage({ id: "maximise_safety" }),
            2: intl.formatMessage({ id: "mostly_safety" }),
            3: intl.formatMessage({ id: "mix_safety" }),
            4: intl.formatMessage({ id: "mostly_return" }),
            5: intl.formatMessage({ id: "maximise_return" }),
        },
        knowledgable: {
            1: intl.formatMessage({ id: "not_at_all" }),
            2: intl.formatMessage({ id: "minimally_knowledgable" }),
            3: intl.formatMessage({ id: "moderately_knowledgable" }),
            4: intl.formatMessage({ id: "competent" }),
            5: intl.formatMessage({ id: "very_knowledgable" }),
        },
        experience: {
            1: intl.formatMessage({ id: "no_experience" }),
            2: intl.formatMessage({ id: "little_experience" }),
            3: intl.formatMessage({ id: "some_experience" }),
            4: intl.formatMessage({ id: "quite_experienced" }),
            5: intl.formatMessage({ id: "very_experienced" }),
        },
        perception: {
            1: intl.formatMessage({ id: "very_risky" }),
            2: intl.formatMessage({ id: "somewhat_risky" }),
            3: intl.formatMessage({ id: "neutral" }),
            4: intl.formatMessage({ id: "somewhat_safe" }),
            5: intl.formatMessage({ id: "very_safe" }),
        },
        loseMoney: {
            1: intl.formatMessage({ id: "sold_investments" }),
            3: intl.formatMessage({ id: "did_nothing" }),
            5: intl.formatMessage({ id: "purchase_investments" }),
        },
        investmentLength: {
            0: intl.formatMessage({ id: "less_5_years" }),
            1: intl.formatMessage({ id: "5_10_years" }),
            2: intl.formatMessage({ id: "more_10_years" }),
        },
    };

    function changeSlide(_current: number, next: number) {
        setSlide(next);
        let selected: boolean = false;
        switch (next) {
            case 0:
                selected = ageRange !== -1;
                break;

            case 1:
                selected = withdrawAnnually !== -1;
                break;

            case 2:
                selected = incomeAssets !== -1;
                break;

            case 3:
                selected = takeRisk !== -1;
                break;

            case 4:
                selected = preference !== -1;
                break;

            case 5:
                selected = knowledgable !== -1;
                break;

            case 6:
                selected = experience !== -1;
                break;

            case 7:
                selected = perception !== -1;
                break;

            case 8:
                selected = loseMoney !== -1;
                break;

            case 9:
                selected = investmentLength !== -1;
                break;

            default:
                break;
        }

        setHasSelected(selected);
    }

    function previousSlide() {
        nav.slickGoTo(slide - 1);
    }

    function nextSlide() {
        nav.slickGoTo(slide + 1);
    }

    function setAnswer(question: string, answer: number) {
        switch (question) {
            case "ageRange":
                setAgeRange(answer);
                break;
            case "withdrawAnnually":
                setWithdrawAnnually(answer);
                break;
            case "incomeAssets":
                setIncomeAssets(answer);
                break;
            case "takeRisk":
                setTakeRisk(answer);
                break;
            case "preference":
                setPreference(answer);
                break;
            case "knowledgable":
                setKnowledgable(answer);
                break;
            case "experience":
                setExperience(answer);
                break;
            case "perception":
                setPerception(answer);
                break;
            case "loseMoney":
                setLoseMoney(answer);
                break;
            case "investmentLength":
                setInvestmentLength(answer);
                break;
            default:
                break;
        }

        setHasSelected(true);
    }

    function getRiskData() {
        return [
            {
                question_id: 1,
                score: ageRange,
                answer: getRiskAnswerText("ageRange", ageRange.toString()),
                question: intl.formatMessage({ id: "how_old" }),
            },
            {
                question_id: 2,
                score: withdrawAnnually,
                answer: getRiskAnswerText("withdrawAnnually", withdrawAnnually.toString()),
                question: intl.formatMessage({ id: "investment_portfolio_annually" }),
            },
            {
                question_id: 3,
                score: incomeAssets,
                answer: getRiskAnswerText("incomeAssets", incomeAssets.toString()),
                question: intl.formatMessage({ id: "income_assets" }),
            },
            {
                question_id: 4,
                score: takeRisk,
                answer: getRiskAnswerText("takeRisk", takeRisk.toString()),
                question: intl.formatMessage({ id: "financial_risks" }),
            },
            {
                question_id: 5,
                score: preference,
                answer: getRiskAnswerText("preference", preference.toString()),
                question: intl.formatMessage({ id: "preference" }),
            },
            {
                question_id: 6,
                score: knowledgable,
                answer: getRiskAnswerText("knowledgable", knowledgable.toString()),
                question: intl.formatMessage({ id: "knowledgeable" }),
            },
            {
                question_id: 7,
                score: experience,
                answer: getRiskAnswerText("experience", experience.toString()),
                question: intl.formatMessage({ id: "experience_buying" }),
            },
            {
                question_id: 8,
                score: perception,
                answer: getRiskAnswerText("perception", perception.toString()),
                question: intl.formatMessage({ id: "perception_stock_market" }),
            },
            {
                question_id: 9,
                score: loseMoney,
                answer: getRiskAnswerText("loseMoney", loseMoney.toString()),
                question: intl.formatMessage({ id: "lose_money" }),
            },
            {
                question_id: 10,
                score: investmentLength,
                answer: getRiskAnswerText("investmentLength", investmentLength.toString()),
                question: intl.formatMessage({ id: "how_long_invest" }),
            },
        ];
    }

    function submitForm() {
        setIsProcessing(true);

        try {
            const delayInMilliseconds = 3500;
            const profile = calculateRisk(getRiskData());

            setTimeout(function () {
                setIsProcessing(false);
                navigate(`/risk_result/${profile}`, { state: getRiskData() });
            }, delayInMilliseconds);
        } catch (e) {
            setIsProcessing(false);
            if (!!toaster && !!toaster.current) {
                const toasterInstance = toaster.current as ToasterInstance;
                toasterInstance.show({
                    message: "Could not build your investment plan",
                    intent: "danger",
                });
            } else {
                alert("Could not build your investment plan");
            }
        }
    }

    function getRiskAnswerText(key: string, sequence: string) {
        return riskAnswers[key][sequence];
    }

    return (
        <React.Fragment>
            <OverlayToaster ref={toaster} position={Position.TOP} />

            <div style={{ paddingTop: "50px", width: "100%", display: "block" }}>
                <Slider asNavFor={nav} ref={(slider: Slider) => setNav(slider)} {...settings}>
                    <div>
                        <div className="risk-question">
                            <H1 className="risk-header">
                                <FormattedMessage id={"how_old"} />
                            </H1>
                            <H6 className="risk-description">
                                <FormattedMessage id={"select_age"} />
                            </H6>
                            <Flex
                                className="risk-answer-flex"
                                flexWrap="wrap"
                                flexDirection="row"
                                justifyContent={"center"}
                                marginTop={"50px"}
                                marginX={"auto"}
                                width={"fit-content"}
                            >
                                <RiskButton
                                    className="risk-answer-button numeric"
                                    active={ageRange === 5}
                                    onClick={() => setAnswer("ageRange", 5)}
                                    text={getRiskAnswerText("ageRange", "5")}
                                />
                                <RiskButton
                                    className="risk-answer-button numeric"
                                    active={ageRange === 4}
                                    onClick={() => setAnswer("ageRange", 4)}
                                    text={getRiskAnswerText("ageRange", "4")}
                                />
                                <RiskButton
                                    className="risk-answer-button numeric"
                                    active={ageRange === 3}
                                    onClick={() => setAnswer("ageRange", 3)}
                                    text={getRiskAnswerText("ageRange", "3")}
                                />
                                <RiskButton
                                    className="risk-answer-button numeric"
                                    active={ageRange === 2}
                                    onClick={() => setAnswer("ageRange", 2)}
                                    text={getRiskAnswerText("ageRange", "2")}
                                />
                                <RiskButton
                                    className="risk-answer-button numeric"
                                    active={ageRange === 1}
                                    onClick={() => setAnswer("ageRange", 1)}
                                    text={getRiskAnswerText("ageRange", "1")}
                                />
                            </Flex>
                        </div>
                    </div>
                    <div>
                        <div className="risk-question">
                            <H1 className="risk-header">
                                <FormattedMessage id={"investment_portfolio_annually"} />
                            </H1>
                            <Flex
                                className="risk-answer-flex"
                                flexWrap="wrap"
                                flexDirection="row"
                                justifyContent={"space-evenly"}
                                marginTop={"50px"}
                                width={"fit-content"}
                                marginX={"auto"}
                            >
                                <RiskButton
                                    className="risk-answer-button"
                                    active={withdrawAnnually === 1}
                                    onClick={() => setAnswer("withdrawAnnually", 1)}
                                    text={getRiskAnswerText("withdrawAnnually", "1")}
                                />
                                <RiskButton
                                    className="risk-answer-button"
                                    active={withdrawAnnually === 0}
                                    onClick={() => setAnswer("withdrawAnnually", 0)}
                                    text={getRiskAnswerText("withdrawAnnually", "0")}
                                />
                            </Flex>
                        </div>
                    </div>
                    <div>
                        <div className="risk-question">
                            <H1 className="risk-header">
                                <FormattedMessage id={"income_assets"} />
                            </H1>
                            <H6 className="risk-description">
                                <FormattedMessage id={"income_assets_eg"} />
                            </H6>
                            <Flex
                                className="risk-answer-flex"
                                flexWrap="wrap"
                                flexDirection="row"
                                justifyContent={"space-evenly"}
                                marginTop={"50px"}
                                width={"fit-content"}
                                marginX={"auto"}
                            >
                                <RiskButton
                                    className="risk-answer-button"
                                    active={incomeAssets === 1}
                                    onClick={() => setAnswer("incomeAssets", 1)}
                                    text={getRiskAnswerText("incomeAssets", "1")}
                                />
                                <RiskButton
                                    className="risk-answer-button"
                                    active={incomeAssets === 0}
                                    onClick={() => setAnswer("incomeAssets", 0)}
                                    text={getRiskAnswerText("incomeAssets", "0")}
                                />
                            </Flex>
                        </div>
                    </div>
                    <div>
                        <div className="risk-question">
                            <H1 className="risk-header">
                                <FormattedMessage id={"financial_risks"} />
                            </H1>
                            <H6 className="risk-description">
                                <FormattedMessage id={"agree_statement"} />
                            </H6>
                            <Flex
                                className="risk-answer-flex"
                                flexWrap="wrap"
                                flexDirection="row"
                                justifyContent={"space-evenly"}
                                marginTop={"50px"}
                                width={"fit-content"}
                                marginX={"auto"}
                            >
                                <RiskButton
                                    className="risk-answer-button font-small"
                                    active={takeRisk === 1}
                                    onClick={() => setAnswer("takeRisk", 1)}
                                    text={getRiskAnswerText("takeRisk", "1")}
                                />
                                <RiskButton
                                    className="risk-answer-button font-small"
                                    active={takeRisk === 2}
                                    onClick={() => setAnswer("takeRisk", 2)}
                                    text={getRiskAnswerText("takeRisk", "2")}
                                />
                                <RiskButton
                                    className="risk-answer-button font-small"
                                    active={takeRisk === 3}
                                    onClick={() => setAnswer("takeRisk", 3)}
                                    text={getRiskAnswerText("takeRisk", "3")}
                                />
                                <RiskButton
                                    className="risk-answer-button font-small"
                                    active={takeRisk === 4}
                                    onClick={() => setAnswer("takeRisk", 4)}
                                    text={getRiskAnswerText("takeRisk", "4")}
                                />
                                <RiskButton
                                    className="risk-answer-button font-small"
                                    active={takeRisk === 5}
                                    onClick={() => setAnswer("takeRisk", 5)}
                                    text={getRiskAnswerText("takeRisk", "5")}
                                />
                            </Flex>
                        </div>
                    </div>
                    <div>
                        <div className="risk-question">
                            <H1 className="risk-header">
                                <FormattedMessage id={"preference"} />
                            </H1>
                            <Flex
                                className="risk-answer-flex"
                                flexWrap="wrap"
                                flexDirection="row"
                                justifyContent={"center"}
                                marginTop={"50px"}
                                marginX={"auto"}
                                width={"fit-content"}
                            >
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={preference === 1}
                                    onClick={() => setAnswer("preference", 1)}
                                    text={getRiskAnswerText("preference", "1")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={preference === 2}
                                    onClick={() => setAnswer("preference", 2)}
                                    text={getRiskAnswerText("preference", "2")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={preference === 3}
                                    onClick={() => setAnswer("preference", 3)}
                                    text={getRiskAnswerText("preference", "3")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={preference === 4}
                                    onClick={() => setAnswer("preference", 4)}
                                    text={getRiskAnswerText("preference", "4")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={preference === 5}
                                    onClick={() => setAnswer("preference", 5)}
                                    text={getRiskAnswerText("preference", "5")}
                                />
                            </Flex>
                        </div>
                    </div>
                    <div>
                        <div className="risk-question">
                            <H1 className="risk-header">
                                <FormattedMessage id={"knowledgeable"} />
                            </H1>
                            <Flex
                                className="risk-answer-flex"
                                flexWrap="wrap"
                                flexDirection="row"
                                justifyContent={"center"}
                                marginTop={"50px"}
                                marginX={"auto"}
                                width={"fit-content"}
                            >
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={knowledgable === 1}
                                    onClick={() => setAnswer("knowledgable", 1)}
                                    text={getRiskAnswerText("knowledgable", "1")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={knowledgable === 2}
                                    onClick={() => setAnswer("knowledgable", 2)}
                                    text={getRiskAnswerText("knowledgable", "2")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={knowledgable === 3}
                                    onClick={() => setAnswer("knowledgable", 3)}
                                    text={getRiskAnswerText("knowledgable", "3")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={knowledgable === 4}
                                    onClick={() => setAnswer("knowledgable", 4)}
                                    text={getRiskAnswerText("knowledgable", "4")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={knowledgable === 5}
                                    onClick={() => setAnswer("knowledgable", 5)}
                                    text={getRiskAnswerText("knowledgable", "5")}
                                />
                            </Flex>
                        </div>
                    </div>
                    <div>
                        <div className="risk-question">
                            <H1 className="risk-header">
                                <FormattedMessage id={"experience_buying"} />
                            </H1>
                            <H6 className="risk-description">
                                <FormattedMessage id={"experience_buying_eg"} />
                            </H6>
                            <Flex
                                className="risk-answer-flex"
                                flexWrap="wrap"
                                flexDirection="row"
                                justifyContent={"center"}
                                marginTop={"50px"}
                                marginX={"auto"}
                                width={"fit-content"}
                            >
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={experience === 1}
                                    onClick={() => setAnswer("experience", 1)}
                                    text={getRiskAnswerText("experience", "1")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={experience === 2}
                                    onClick={() => setAnswer("experience", 2)}
                                    text={getRiskAnswerText("experience", "2")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={experience === 3}
                                    onClick={() => setAnswer("experience", 3)}
                                    text={getRiskAnswerText("experience", "3")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={experience === 4}
                                    onClick={() => setAnswer("experience", 4)}
                                    text={getRiskAnswerText("experience", "4")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={experience === 5}
                                    onClick={() => setAnswer("experience", 5)}
                                    text={getRiskAnswerText("experience", "5")}
                                />
                            </Flex>
                        </div>
                    </div>
                    <div>
                        <div className="risk-question">
                            <H1 className="risk-header">
                                <FormattedMessage id={"perception_stock_market"} />
                            </H1>
                            <Flex
                                className="risk-answer-flex"
                                flexWrap="wrap"
                                flexDirection="row"
                                justifyContent={"center"}
                                marginTop={"50px"}
                                marginX={"auto"}
                                width={"fit-content"}
                            >
                                <RiskButton
                                    className="risk-answer-button font-small"
                                    active={perception === 1}
                                    onClick={() => setAnswer("perception", 1)}
                                    text={getRiskAnswerText("perception", "1")}
                                />
                                <RiskButton
                                    className="risk-answer-button font-small"
                                    active={perception === 2}
                                    onClick={() => setAnswer("perception", 2)}
                                    text={getRiskAnswerText("perception", "2")}
                                />
                                <RiskButton
                                    className="risk-answer-button font-small"
                                    active={perception === 3}
                                    onClick={() => setAnswer("perception", 3)}
                                    text={getRiskAnswerText("perception", "3")}
                                />
                                <RiskButton
                                    className="risk-answer-button font-small"
                                    active={perception === 4}
                                    onClick={() => setAnswer("perception", 4)}
                                    text={getRiskAnswerText("perception", "4")}
                                />
                                <RiskButton
                                    className="risk-answer-button font-small"
                                    active={perception === 5}
                                    onClick={() => setAnswer("perception", 5)}
                                    text={getRiskAnswerText("perception", "5")}
                                />
                            </Flex>
                        </div>
                    </div>
                    <div>
                        <div className="risk-question">
                            <H1 className="risk-header">
                                <FormattedMessage id={"lose_money"} />
                            </H1>
                            <H6 className="risk-description">
                                <FormattedMessage id={"answer_experience"} />
                            </H6>
                            <Flex
                                className="risk-answer-flex"
                                flexWrap="wrap"
                                flexDirection="row"
                                justifyContent={"center"}
                                marginTop={"50px"}
                                marginX={"auto"}
                                width={"fit-content"}
                            >
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={loseMoney === 1}
                                    onClick={() => setAnswer("loseMoney", 1)}
                                    text={getRiskAnswerText("loseMoney", "1")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={loseMoney === 3}
                                    onClick={() => setAnswer("loseMoney", 3)}
                                    text={getRiskAnswerText("loseMoney", "3")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={loseMoney === 5}
                                    onClick={() => setAnswer("loseMoney", 5)}
                                    text={getRiskAnswerText("loseMoney", "5")}
                                />
                            </Flex>
                        </div>
                    </div>
                    <div>
                        <div className="risk-question">
                            <H1 className="risk-header">
                                <FormattedMessage id={"how_long_invest"} />
                            </H1>
                            <Flex
                                className="risk-answer-flex"
                                flexWrap="wrap"
                                flexDirection="row"
                                justifyContent={"space-around"}
                                marginTop={"50px"}
                                marginX={"auto"}
                                width={"fit-content"}
                            >
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={investmentLength === 0}
                                    onClick={() => setAnswer("investmentLength", 0)}
                                    text={getRiskAnswerText("investmentLength", "0")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={investmentLength === 1}
                                    onClick={() => setAnswer("investmentLength", 1)}
                                    text={getRiskAnswerText("investmentLength", "1")}
                                />
                                <RiskButton
                                    className="risk-answer-button tall font-small"
                                    active={investmentLength === 2}
                                    onClick={() => setAnswer("investmentLength", 2)}
                                    text={getRiskAnswerText("investmentLength", "2")}
                                />
                            </Flex>
                        </div>
                    </div>
                </Slider>
                <Flex justifyContent={"center"}>
                    {slide > 0 && (
                        <AnchorButton
                            large={true}
                            minimal={true}
                            className="risk-back-button"
                            onClick={previousSlide}
                            icon="chevron-left"
                            text={intl.formatMessage({ id: "back" })}
                        ></AnchorButton>
                    )}

                    {slide < 9 ? (
                        <AnchorButton
                            large={true}
                            disabled={!hasSelected}
                            className="risk-next-button"
                            onClick={nextSlide}
                            rightIcon="chevron-right"
                            text={intl.formatMessage({ id: "next_question" })}
                        ></AnchorButton>
                    ) : (
                        <AnchorButton
                            large={true}
                            disabled={!hasSelected}
                            className="risk-next-button"
                            rightIcon="chevron-right"
                            onClick={submitForm}
                            text={intl.formatMessage({ id: "submit" })}
                        ></AnchorButton>
                    )}
                </Flex>

                <Dialog {...dialogProps} isOpen={isProcessing}>
                    <div style={{ padding: "20px" }}>
                        <Flex justifyContent={"center"}>
                            <img src={Loading} alt="load" className="loading-icon" />
                        </Flex>

                        <H1 className="risk-header">
                            <FormattedMessage id={"give_moment"} />
                        </H1>
                        <H6 className="risk-description">
                            <FormattedMessage id={"building_investment"} />
                        </H6>
                    </div>
                </Dialog>
            </div>
        </React.Fragment>
    );
};

export default Risk;
