import { Alignment, Button, Classes, Navbar, NavbarDivider, NavbarGroup } from "@blueprintjs/core";
import { useContext } from "react";
import { Box, Flex } from "rebass";
import Logo from "../assets/asnb.svg";
import { Context } from "../components/Wrapper";

const HomeNav = () => {
    const context = useContext(Context);

    return (
        <Flex>
            <Box flex="1 1 auto" width={0.2}>
                <a href="https://msam.asnb.com.my/">
                    <img src={Logo} alt="logo" className="brand-logo" />
                </a>
            </Box>
            <Box flex="1 1 auto" width={0.15}>
                <Navbar
                    className="language-select"
                    style={{
                        boxShadow: "none",
                        display: "flex",
                        justifyContent: "end",
                    }}
                >
                    <NavbarGroup align={Alignment.CENTER} style={{ display: "flex", textAlign: "center" }}>
                        <Button
                            active={context?.locale.split("-")[0] === "en"}
                            onClick={() => context?.selectLanguage("en-GB")}
                            className={`${Classes.MINIMAL} navbar-button`}
                            text="EN"
                        />
                        <NavbarDivider />
                        <Button
                            active={context?.locale === "ms"}
                            onClick={() => context?.selectLanguage("ms")}
                            className={`${Classes.MINIMAL} navbar-button`}
                            text="BM"
                        />
                    </NavbarGroup>
                </Navbar>
            </Box>
        </Flex>
    );
};

export default HomeNav;
