import {
    Button,
    Card,
    Classes,
    Dialog,
    H1,
    H2,
    H6,
    InputGroup,
    OverlayToaster,
    Position,
    ToasterInstance,
} from "@blueprintjs/core";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Box, Flex } from "rebass";
import Loading from "../assets/loading.svg";
import Aggressive from "../assets/profiles/aggressive.svg";
import Moderate from "../assets/profiles/moderate.svg";
import ModeratelyAggressive from "../assets/profiles/moderately_aggressive.svg";
import ModeratelyConservative from "../assets/profiles/moderately_conservative.svg";
import GetPorfolio from "../assets/profiles/roboadvisor.svg";
import VeryAggresive from "../assets/profiles/very_aggressive.svg";
import VeryConservative from "../assets/profiles/very_conservative.svg";
import { Context } from "./Wrapper";

const VERY_CONSERVATIVE = "0";
const MODERATELY_CONSERVATIVE = "1";
const MODERATE = "2";
const MODERATELY_AGGRESSIVE = "3";
const AGGRESSIVE = "4";
const VERY_AGGRESSIVE = "5";

const RiskResult = () => {
    let toaster = React.createRef<OverlayToaster>();
    const context = useContext(Context);
    const intl = useIntl();
    const { id } = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [expectedReturn, setExpectedReturn] = useState(4.5);
    const [expectedVolatility, setExpectedVolatility] = useState(7.9);
    const [globalPercent, setGlobalPercent] = useState(15);
    const [malaysianPercent, setMalaysianPercent] = useState(21);
    const [sukukPercent, setSukukPercent] = useState(63);
    const [cashPercent, setCashPercent] = useState(1);
    const [email, setEmail] = useState("");
    const [isOpenTooltip1, setIsOpenTooltip1] = useState(false);
    const [isOpenTooltip2, setIsOpenTooltip2] = useState(false);

    const [isProcessing, setIsProcessing] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    const loadingDialogProps = {
        autoFocus: true,
        canEscapeKeyClose: false,
        canOutsideClickClose: false,
        enforceFocus: true,
        shouldReturnFocusOnClose: true,
        usePortal: true,
    };
    const completeDialogProps = {
        autoFocus: true,
        canEscapeKeyClose: true,
        canOutsideClickClose: true,
        enforceFocus: true,
        shouldReturnFocusOnClose: true,
        usePortal: true,
    };

    const HtmlTooltip1 = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip
            {...props}
            open={isOpenTooltip1}
            onClick={() => setIsOpenTooltip1(!isOpenTooltip1)}
            classes={{ popper: className }}
        />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#E1E8EB",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
            borderRadius: "10px",
            lineHeight: "20px",
            padding: "15px",
        },
    }));

    const HtmlTooltip2 = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip
            {...props}
            open={isOpenTooltip2}
            onClick={() => setIsOpenTooltip2(!isOpenTooltip2)}
            classes={{ popper: className }}
        />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#E1E8EB",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
            borderRadius: "10px",
            lineHeight: "20px",
            padding: "15px",
        },
    }));

    useEffect(() => {
        let expected_return = 4.5;
        let expected_volatility = 7.9;
        let global_percent = 15;
        let malaysian_percent = 21;
        let sukuk_percent = 63;
        let cash_percent = 1;

        switch (id) {
            case VERY_CONSERVATIVE:
                expected_return = 3.7;
                expected_volatility = 4.9;
                global_percent = 1;
                malaysian_percent = 1;
                sukuk_percent = 97;
                cash_percent = 1;
                break;

            case MODERATELY_CONSERVATIVE:
                expected_return = 4.1;
                expected_volatility = 6.2;
                global_percent = 9;
                malaysian_percent = 11;
                sukuk_percent = 79;
                cash_percent = 1;
                break;

            case MODERATE:
                expected_return = 4.5;
                expected_volatility = 7.9;
                global_percent = 15;
                malaysian_percent = 21;
                sukuk_percent = 63;
                cash_percent = 1;
                break;

            case MODERATELY_AGGRESSIVE:
                expected_return = 4.9;
                expected_volatility = 10.2;
                global_percent = 22;
                malaysian_percent = 32;
                sukuk_percent = 45;
                cash_percent = 1;
                break;

            case AGGRESSIVE:
                expected_return = 5.4;
                expected_volatility = 12.5;
                global_percent = 34;
                malaysian_percent = 40;
                sukuk_percent = 25;
                cash_percent = 1;
                break;

            case VERY_AGGRESSIVE:
                expected_return = 5.8;
                expected_volatility = 14.7;
                global_percent = 56;
                malaysian_percent = 40;
                sukuk_percent = 3;
                cash_percent = 1;
                break;

            default:
                expected_return = 4.5;
                expected_volatility = 7.9;
                global_percent = 15;
                malaysian_percent = 21;
                sukuk_percent = 63;
                cash_percent = 1;
                break;
        }

        setExpectedReturn(expected_return);
        setExpectedVolatility(expected_volatility);
        setGlobalPercent(global_percent);
        setMalaysianPercent(malaysian_percent);
        setSukukPercent(sukuk_percent);
        setCashPercent(cash_percent);
    }, [id]);

    function sendEmail() {
        if (!email) {
            alert("Please enter valid email");
        }

        setIsProcessing(true);

        let payload = {
            expectedReturn,
            expectedVolatility,
            globalPercent,
            malaysianPercent,
            sukukPercent,
            cashPercent,
            type: getProfileText(),
            lang: getLanguage(),
            email,
            userAnswers: JSON.stringify(state),
        };

        axios
            .post("https://msam.asnb.com.my/backend/email-profile", payload)
            .then((res) => {
                setIsProcessing(false);
                if (res.data === 500) {
                    if (!!toaster && !!toaster.current) {
                        const toasterInstance = toaster.current as ToasterInstance;
                        toasterInstance.show({
                            message: "There is something wrong processing your request.",
                            intent: "danger",
                        });
                    } else {
                        alert("There is something wrong processing your request.");
                    }
                } else {
                    setIsCompleted(true);
                }
            })
            .catch((err) => {
                setIsProcessing(false);
                if (!!toaster && !!toaster.current) {
                    const toasterInstance = toaster.current as ToasterInstance;
                    toasterInstance.show({
                        message: "There is something wrong processing your request.",
                        intent: "danger",
                    });
                } else {
                    alert("There is something wrong processing your request.");
                }
            })
            .finally(() => {});
    }

    function getLanguage() {
        if (context?.locale === "ms") {
            return "bm";
        } else {
            return "en";
        }
    }

    function redoAssessment() {
        navigate("/risk");
    }

    function getProfileImage() {
        switch (id) {
            case VERY_CONSERVATIVE:
                return VeryConservative;

            case MODERATELY_CONSERVATIVE:
                return ModeratelyConservative;

            case MODERATE:
                return Moderate;

            case MODERATELY_AGGRESSIVE:
                return ModeratelyAggressive;

            case AGGRESSIVE:
                return Aggressive;

            case VERY_AGGRESSIVE:
                return VeryAggresive;

            default:
                return Moderate;
        }
    }

    function getProfileText() {
        switch (id) {
            case VERY_CONSERVATIVE:
                return "very_conservative";

            case MODERATELY_CONSERVATIVE:
                return "moderately_conservative";

            case MODERATE:
                return "moderate";

            case MODERATELY_AGGRESSIVE:
                return "moderately_aggressive";

            case AGGRESSIVE:
                return "aggressive";

            case VERY_AGGRESSIVE:
                return "very_aggressive";

            default:
                return "moderate";
        }
    }

    function closeDialog() {
        setIsCompleted(false);
        navigate(`/`);
    }

    function getExpectedReturnDescription() {
        const portfolioText = intl.formatMessage({ id: getProfileText() });
        const description = intl.formatMessage({
            id: "expected_return_description",
        });

        return description.replace("{portfolio}", portfolioText);
    }

    return (
        <div className="risk-result-page" style={{ width: "100%" }}>
            <OverlayToaster ref={toaster} position={Position.TOP} />

            <div style={{ textAlign: "right", padding: "10px" }}>
                <Button
                    className="redo-button"
                    icon="repeat"
                    minimal={true}
                    onClick={redoAssessment}
                    text={intl.formatMessage({ id: "redo_assessment" })}
                ></Button>
            </div>
            <Card className={[Classes.ELEVATION_2, "risk-result-heading"].join(" ")}>
                <img
                    src={getProfileImage()}
                    alt="logo"
                    style={{
                        marginBottom: "15px",
                        display: "inline-block",
                        width: "50px",
                    }}
                />
                <H1 className="profile-result-heading">
                    <FormattedMessage id={`${getProfileText()}_heading`} />
                </H1>
                <H6 className="profile-result-description">
                    <FormattedMessage id={`${getProfileText()}_description`} />
                </H6>
            </Card>
            <Card className={[Classes.ELEVATION_2, "risk-result-description"].join(" ")}>
                <H2 className="profile-explanation-heading">
                    <FormattedMessage id={`${getProfileText()}_explanation_heading`} />
                </H2>
                <H6 className="profile-result-description">
                    <FormattedMessage id={`${getProfileText()}_explanation_description`} />
                </H6>
                <Flex paddingY={"15px"}>
                    <Box width={0.5}>
                        <H6 className="profile-result-description" style={{ textAlign: "left" }}>
                            <FormattedMessage id={`expected_return`} />
                        </H6>
                        <div>
                            <H6
                                className="profile-percentage"
                                style={{
                                    textAlign: "left",
                                    display: "inline-block",
                                }}
                            >
                                {expectedReturn}%
                            </H6>
                            <HtmlTooltip1 title={<p>{getExpectedReturnDescription()}</p>}>
                                <div
                                    style={{
                                        display: "inline-block",
                                        padding: "0 10px",
                                    }}
                                >
                                    <HelpOutlineIcon style={{ paddingTop: "4px" }} />
                                </div>
                            </HtmlTooltip1>
                        </div>
                    </Box>
                    <Box width={0.5}>
                        <H6 className="profile-result-description">
                            <FormattedMessage id={`expected_volatility`} />
                        </H6>
                        <div>
                            <H6
                                className="profile-percentage"
                                style={{
                                    textAlign: "left",
                                    display: "inline-block",
                                }}
                            >
                                {expectedVolatility}%
                            </H6>
                            <HtmlTooltip2
                                title={
                                    <React.Fragment>
                                        <p>
                                            <FormattedMessage id={`expected_volatility_description`} />
                                        </p>
                                    </React.Fragment>
                                }
                            >
                                <div
                                    style={{
                                        display: "inline-block",
                                        padding: "0 10px",
                                    }}
                                >
                                    <HelpOutlineIcon style={{ paddingTop: "4px" }} />
                                </div>
                            </HtmlTooltip2>
                        </div>
                    </Box>
                </Flex>

                <H2 className="profile-explanation-heading">
                    <FormattedMessage id={`composition`} />
                </H2>
                <Flex>
                    <Box
                        width={globalPercent / 100}
                        height={"40px"}
                        style={{
                            background: "#002349",
                            borderRadius: "16px 0px 0px 16px",
                        }}
                    />
                    <Box mx={1} width={malaysianPercent / 100} height={"40px"} style={{ background: "#004692" }} />
                    <Box mr={1} width={sukukPercent / 100} height={"40px"} style={{ background: "#5A94D4" }} />
                    <Box
                        width={cashPercent / 100}
                        height={"40px"}
                        style={{
                            background: "#CAE0F8",
                            borderRadius: "0px 16px 16px 0px",
                        }}
                    />
                </Flex>

                <div style={{ paddingTop: "30px" }}>
                    <div>
                        <H6 className="profile-result-description" style={{ display: "inline-block" }}>
                            <FormattedMessage id={`asset_type`} />
                        </H6>
                        <H6 className="profile-result-description right" style={{ display: "inline-block" }}>
                            <FormattedMessage id={`allocation`} />
                        </H6>
                    </div>

                    <div>
                        <Box
                            width={"20px"}
                            height={"20px"}
                            mr={3}
                            style={{
                                background: "#002349",
                                borderRadius: "8px",
                                display: "inline-block",
                                verticalAlign: "text-bottom",
                            }}
                        />
                        <H6 className="profile-result-description bold" style={{ display: "inline-block" }}>
                            <FormattedMessage id={`global_stocks`} />
                        </H6>
                        <H6 className="profile-result-description bold right" style={{ display: "inline-block" }}>
                            {globalPercent}%
                        </H6>
                    </div>

                    <div>
                        <Box
                            width={"20px"}
                            height={"20px"}
                            mr={3}
                            style={{
                                background: "#004692",
                                borderRadius: "8px",
                                display: "inline-block",
                                verticalAlign: "text-bottom",
                            }}
                        />
                        <H6 className="profile-result-description bold" style={{ display: "inline-block" }}>
                            <FormattedMessage id={`malaysian_stocks`} />
                        </H6>
                        <H6 className="profile-result-description bold right" style={{ display: "inline-block" }}>
                            {malaysianPercent}%
                        </H6>
                    </div>

                    <div>
                        <Box
                            width={"20px"}
                            height={"20px"}
                            mr={3}
                            style={{
                                background: "#5A94D4",
                                borderRadius: "8px",
                                display: "inline-block",
                                verticalAlign: "text-bottom",
                            }}
                        />
                        <H6 className="profile-result-description bold" style={{ display: "inline-block" }}>
                            <FormattedMessage id={`sukuk_bonds`} />
                        </H6>
                        <H6 className="profile-result-description bold right" style={{ display: "inline-block" }}>
                            {sukukPercent}%
                        </H6>
                    </div>

                    <div>
                        <Box
                            width={"20px"}
                            height={"20px"}
                            mr={3}
                            style={{
                                background: "#CAE0F8",
                                borderRadius: "8px",
                                display: "inline-block",
                                verticalAlign: "text-bottom",
                            }}
                        />
                        <H6 className="profile-result-description bold" style={{ display: "inline-block" }}>
                            <FormattedMessage id={`cash`} />
                        </H6>
                        <H6 className="profile-result-description bold right" style={{ display: "inline-block" }}>
                            {cashPercent}%
                        </H6>
                    </div>
                </div>
            </Card>

            <Card className={[Classes.ELEVATION_2, "email-flex"].join(" ")} style={{ borderRadius: "20px" }}>
                <Flex flexWrap="wrap" flexDirection="row">
                    <Box width={[1, 0.25]} mt={2} mr={2}>
                        <img
                            src={GetPorfolio}
                            alt="logo"
                            style={{
                                marginBottom: "15px",
                                display: "inline-block",
                                width: "130px",
                            }}
                        />
                    </Box>
                    <Box width={[1, 0.75]}>
                        <div>
                            <H2 className="profile-explanation-heading">
                                <FormattedMessage id={`get_portfolio`} />
                            </H2>
                            <H2 className="profile-result-description" style={{ paddingBottom: "10px" }}>
                                <FormattedMessage id={`enter_email_portfolio`} />
                            </H2>
                            <InputGroup
                                type="text"
                                disabled={false}
                                large={true}
                                rightElement={
                                    <Button
                                        onClick={sendEmail}
                                        intent={"none"}
                                        text={intl.formatMessage({
                                            id: "send_copy",
                                        })}
                                        style={{
                                            margin: "0",
                                            height: "40px",
                                            padding: "15px",
                                        }}
                                        fill
                                    />
                                }
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="example@mail.com"
                                className={"send-copy-button"}
                                value={email}
                            />
                        </div>
                    </Box>
                </Flex>
            </Card>

            <Dialog {...loadingDialogProps} isOpen={isProcessing}>
                <div style={{ padding: "20px" }}>
                    <Flex justifyContent={"center"}>
                        <img src={Loading} alt="load" className="loading-icon" />
                    </Flex>

                    <H1 className="risk-header">
                        <FormattedMessage id={"give_moment"} />
                    </H1>
                    <H6 className="risk-description">
                        <FormattedMessage id={"sending_over_risk"} />
                    </H6>
                </div>
            </Dialog>
            <Dialog {...completeDialogProps} isOpen={isCompleted}>
                <div style={{ padding: "20px" }}>
                    <Flex justifyContent={"center"}>
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                    </Flex>

                    <H1 className="risk-header">
                        <FormattedMessage id={"email_sent"} />
                    </H1>
                    <H6 className="risk-description">
                        <FormattedMessage id={"sent_over_risk"} />
                    </H6>
                </div>
                <div className={`${Classes.DIALOG_FOOTER} complete-dialog-footer`}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={closeDialog}>
                            <FormattedMessage id={"close"} />
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default RiskResult;
