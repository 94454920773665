import { Button, ButtonProps } from "@blueprintjs/core";
import { Box } from "rebass";

export const RiskButton: React.FC<ButtonProps> = ({ ...props }) => {
    return (
        <Box textAlign={"center"} width={[1, "auto"]}>
            <Button {...props} />
        </Box>
    );
};
