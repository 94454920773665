import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import English from "../lang/en.json";
import Malay from "../lang/ms.json";

interface AppContextInterface {
    locale: string;
    selectLanguage: (e: any) => void;
}

export const Context = React.createContext<AppContextInterface | null>(null);

const local = navigator.language;
let lang: any;
if (local.split("-")[0] === "en") {
    lang = English;
} else if (local === "ms") {
    lang = Malay;
}

const Wrapper = (props: any) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    function selectLanguage(newLocale: string) {
        setLocale(newLocale);

        if (newLocale.split("-")[0] === "en") {
            setMessages(English);
        } else if (newLocale === "ms") {
            setMessages(Malay);
        }
    }
    return (
        <Context.Provider value={{ locale, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
};
export default Wrapper;
