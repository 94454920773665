import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Flex } from "rebass";
import { RecoilRoot } from "recoil";
import "./App.css";

import { FormattedMessage } from "react-intl";
import HomeNav from "./components/HomeNav";
import HomePage from "./components/HomePage";
import Risk from "./components/Risk";
import RiskResult from "./components/RiskResult";
import ScrollToTop from "./components/ScrollToTop";

function App() {
    return (
        <div>
            <RecoilRoot>
                <BrowserRouter>
                    <ScrollToTop />
                    <div
                        className="main-page"
                        style={{
                            position: "relative",
                            minHeight: "98vh",
                            float: "none",
                            maxWidth: "100%",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                height: "5vh",
                            }}
                        >
                            <HomeNav />
                        </div>
                        <div style={{ paddingBottom: "65px" }}>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/risk/*" element={<Risk />} />
                                <Route
                                    path="/risk_result/:id"
                                    element={<RiskResult />}
                                />
                            </Routes>
                        </div>
                        <Flex justifyContent={"center"}>
                            <footer className="fixed-footer">
                                <p>
                                    <FormattedMessage id={"footer"} />
                                </p>
                            </footer>
                        </Flex>
                    </div>
                </BrowserRouter>
            </RecoilRoot>
        </div>
    );
}

export default App;
