export interface RiskProfileResponse {
    question_id: number;
    score: number;
}

export function calculateRisk(userResponse: RiskProfileResponse[]) : number {

    const behaviorialCutOffStartIndex = 3;
    const behaviorialCutOffEndIndex = 9;
    const behaviorialPortion = userResponse.slice(behaviorialCutOffStartIndex, behaviorialCutOffEndIndex);
    const timeHorizon = behaviorialPortion.pop();
    const ageResponseIndex = 0;
    const needForLiquidityIndex = 1;
    const riskCapacityIndex = 2;
    const lowToleranceThreshold = 13;
    const mediumToleranceThreshold = 22;
    const highToleranceThreshold = 30;

    let riskTakingAbility : string;
    let behaviorialLossTolerance : string;
    let timeHorizonTolerance : string;

    try {

        // process risk taking ability
        const ageResponse = userResponse[ageResponseIndex].score;
        const needForLiquidity = userResponse[needForLiquidityIndex].score;
        const riskCapacity = userResponse[riskCapacityIndex].score;

        if(ageResponse === 1 || (needForLiquidity && !riskCapacity)) {
            riskTakingAbility = 'LOW';
        } else if(!needForLiquidity && riskCapacity) {
            riskTakingAbility = 'HIGH';
        } else {
            riskTakingAbility = 'MEDIUM';
        }

        // process behaviorial portion
        const sum = behaviorialPortion.reduce((a, b) => a + b.score,0);
        const measures = [ lowToleranceThreshold, mediumToleranceThreshold, highToleranceThreshold ];
        const behaviorialPortionRating = measures.findIndex(element => sum <= element);

        switch(behaviorialPortionRating){
            case(0):
                behaviorialLossTolerance = 'LOW';
                break
            case(1):
                behaviorialLossTolerance = 'MEDIUM';
                break
            default:
                behaviorialLossTolerance = 'HIGH';
                break
        }

        // process time horizon
        const timeHorizonScore = timeHorizon?.score;

        switch(timeHorizonScore){
            case(0):
                timeHorizonTolerance = 'SHORT';
                break
            case(1):
                timeHorizonTolerance = 'MEDIUM';
                break
            default:
                timeHorizonTolerance = 'LONG';
                break
        }

        const nonTemporalRecommendationRating = nonTemporalRecommendation(riskTakingAbility, behaviorialLossTolerance);
        const temporalRecommendationRating = temporalRecommendation(nonTemporalRecommendationRating, timeHorizonTolerance);

        return temporalRecommendationRating

    } catch(e) {
        throw new Error('Error calculating risk profile')
    }
}

function nonTemporalRecommendation(riskTaking : string, behaviorialLoss : string) : string {

    const RISK_SEEKING_1 = (riskTaking === 'HIGH' && behaviorialLoss === 'HIGH');
    const RISK_NEUTRAL_1 = (riskTaking === 'MEDIUM' && behaviorialLoss === 'MEDIUM');
    const RISK_NEUTRAL_2 = (riskTaking === 'HIGH' && behaviorialLoss === 'MEDIUM');
    const RISK_NEUTRAL_3 = (riskTaking === 'MEDIUM' && behaviorialLoss === 'HIGH');

    if(RISK_SEEKING_1) {
        return 'RISK_SEEKING'
    } else if(
        RISK_NEUTRAL_1 || 
        RISK_NEUTRAL_2 || 
        RISK_NEUTRAL_3
    ) {
        return 'RISK_NEUTRAL'
    } else {
        return 'RISK_AVERSE'
    }

}

function temporalRecommendation(nonTemporalRecommendation : string, timeHorizon : string) : number {

    const VERY_CONSERVATIVE = 0;
    const MODERATELY_CONSERVATIVE = 1;
    const MODERATE = 2;
    const MODERATELY_AGGRESSIVE = 3;
    const AGGRESSIVE = 4;
    const VERY_AGGRESSIVE = 5;
    const nonTemporalRecommendationIndex = ['RISK_AVERSE', 'RISK_NEUTRAL', 'RISK_SEEKING'].indexOf(nonTemporalRecommendation);
    const timeHorizonIndex = ['SHORT', 'MEDIUM', 'LONG'].indexOf(timeHorizon);
    const finalRecommendation : number[][] = [
        [VERY_CONSERVATIVE, MODERATE, MODERATELY_AGGRESSIVE],
        [MODERATELY_CONSERVATIVE, MODERATELY_AGGRESSIVE, AGGRESSIVE],
        [MODERATE, AGGRESSIVE, VERY_AGGRESSIVE]
    ];
    return finalRecommendation[nonTemporalRecommendationIndex][timeHorizonIndex];
}