import { AnchorButton, H1, H6 } from "@blueprintjs/core";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box, Flex } from "rebass";
import ProfileIcon from "../assets/home/risk_profile.svg";

const HomePage = () => {
    const intl = useIntl();
    const navigate = useNavigate();

    function redirectPage() {
        navigate("/risk");
    }

    return (
        <div>
            <Flex flexWrap="wrap" flexDirection="row">
                <Box className="home-box" width={[1, 0.45, 0.5]} pr={1}>
                    <div className="home-card icon-left">
                        <img className="home-icons" src={ProfileIcon} alt="logo" />
                    </div>
                </Box>
                <Box className="home-box" width={[1, 0.55, 0.5]} pl={1}>
                    <div className="home-card text-right">
                        <H1 className="home-header">
                            <FormattedMessage id={"what_level_header"} />
                        </H1>
                        <H6 className="home-summary">
                            <FormattedMessage id={"what_level_summary"} />
                        </H6>
                        <AnchorButton
                            large={true}
                            className="home-button"
                            onClick={() => redirectPage()}
                            rightIcon="arrow-right"
                            text={intl.formatMessage({ id: "take_the_quiz" })}
                        ></AnchorButton>
                    </div>
                </Box>
            </Flex>
        </div>
    );
};

export default HomePage;
